import React from 'react'
import HeaderEdit from '../components/header-edit'
import { makeStyles } from '@material-ui/styles'
import { Theme, Divider } from "@material-ui/core"
import ListEntities from "../components/product/list-entities"
import Slider from '../components/slider'
import ISlide from '../interfaces/ISlide'
import {Link} from 'gatsby'
import Home from '@material-ui/icons/Home'

const useStyles = makeStyles((theme: Theme) => {
    return {
        productRoot: {
            display: 'flex',
            marginTop: 90,
            padding: '10px 100px',
        },
        base50pr:{
            flexBasis: '50%'
        },
        descriptionWrapper:{
            direction: 'rtl',
            padding: '10px 20px',
        },
        section:{
           textAlign: 'center',
           '& span':{
            margin: '20px 0 10px 0',
            display: 'block',
            fontSize: '28px',
            fontWeight: 'bold',
            
           }
        },
        mainImage: {
            padding: '10px 20px',
            '& img':{
                border: 'solid 1px transparence',
                borderRadius: '10px',
                width: '100%',
                height: 'auto',
            }
        },
        productTitleWrapper:{
            display: 'flex', justifyContent: 'center', padding: 20,
            textAlign: 'center',
        },
        productTitle:{
            fontWeight: 'bold',
            fontSize: '36px',
            textDecoration: 'underline',
            width: '100%',
            marginRight: 20,
        },
        divider: {
            width: '80%', margin: '0 auto 20px auto', 
        },
        divHeaderEdit:{
            display:'block'
        },
        homeWrapper:{
            display:'none',
            marginRight: 'auto'
        },
        '@media (max-width: 420px)':{ 
            productRoot: {
                display: 'flex',
                flexWrap: 'wrap',
                marginTop: 0,
                padding: '0',
            },
            mainImage: {
                padding: 0,
                '& img':{
                    border: 'unset',
                    borderRadius: 'unset',
                }
            },          
            base50pr:{
                flexBasis: 'unset'
            },
            descriptionWrapper:{
                direction: 'rtl',
                padding: '5px 5px',
            },
            divHeaderEdit:{
                display:'none'
            },
            homeWrapper:{
                display:'block'
            },
           
        }
    }
})

const Product: React.FC<any> = ({pageContext}) => {
    
    const classes = useStyles()
    const {product} = pageContext
    console.log('page context product : ', product.name, product)

    return (
        <div>
            <div className={classes.divHeaderEdit}>
                <HeaderEdit/>
            </div>
            <div className={classes.productRoot}>
                <div className={classes.base50pr +' '+ classes.mainImage}>
                    {/* <img src={`/images/${product.images}`} alt=""/> */}
                    <Slider slides={product.images as ISlide[]} options={{}}/>
                </div> 
                <div className={classes.base50pr + ' '+ classes.descriptionWrapper}>
                    <div className={classes.productTitleWrapper}>
                        <span className={classes.productTitle}>{product.name}</span>
                        <div className={classes.homeWrapper}>
                            <Link to="/products">
                                <Home style={{color:'#159615'}}/>
                            </Link>
                        </div>
                    </div>
                    <section className={classes.section}>
                        <span>מחיר</span>
                        <Divider className={classes.divider}/>
                        <ListEntities entities={product.sizes} sizeEntity={1} />
                    </section>
                    
                    {product.ingredients.length > 0 ? 
                    <>
                        <section className={classes.section}>
                            <span>מרכבים</span>
                            <Divider className={classes.divider}/>
                            <ListEntities entities={product.ingredients} sizeEntity={5}/>
                        </section>
                        
                     </>:''}
                     {product.additions.length > 0 ? 
                     <>
                        <section className={classes.section}>
                            <span>תוספות</span>
                            <Divider className={classes.divider}/> 
                            <ListEntities entities={product.additions} sizeEntity={3}/>
                        </section>
                     </>: ''}
                     {product.sauces.length > 0 ? 
                     <>
                    <section className={classes.section}>
                        <span>רטבים</span>
                        <Divider className={classes.divider}/> 
                        <ListEntities entities={product.sauces} sizeEntity={5}/>
                    </section>
                    </>: ''}
                </div>                
            </div>
        </div>
    )
}

export default Product
