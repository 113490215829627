import React from 'react'
import {makeStyles, Theme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) => {
    
    return {
        mainUl: {
            display: 'flex',
            flexWrap: 'wrap',
            textAlign: 'right',
            listStyle: 'none',
            '& li':{                
                margin: '5px',
                display: 'flex',
                alignItems: 'center'
            },            
        },
        listStyleListDiv: {
            border: 'solid 3px green',
            borderRadius: '50%',
            width: 10,
            height: 10,
            marginLeft: 5
        },
        spanPrice: {
            fontWeight: 'bold  !important', fontSize: '18px  !important', margin: '0 15px 0 0  !important'
        },
        '@media (max-width: 420px)':{ 
            mainUl: {
                marginLeft: 0,
                '& li.wrapLine':{
                    flexBasis: '45% !important'
                },
                '& li.fullLine':{
                    flexBasis: '100% !important'
                }
            },
            spanPrice: {
               margin: '0 5px 0 0 !important',
               fontSize: '14px  !important'
            },
        }
       
    }
})

interface IEntity{
    title: string
    price: string
}

interface IPropsEntities{
    entities: IEntity[]
    sizeEntity: number
}

const ListEntities: React.FC<IPropsEntities> = (props) => {

    const {entities} = props
    const classes = useStyles()
    const entityWidth = 100 / props.sizeEntity
    const size = props.sizeEntity === 1 ? 'fullLine' : 'wrapLine'
    console.log('page context entities : ', props)
    return (
        <div>
            <ul className={classes.mainUl}>
                {entities && entities.map((entity, i)=>{
                    const price = entity.price !== "" ? '- ' + entity.price + ' ש״ח' : ''
                    return <li key={i+entity.title} style={{flexBasis: `${entityWidth}%`}} className={size}>
                            <div className={classes.listStyleListDiv}></div>{`${entity.title}`} <span className={classes.spanPrice}>{price}</span>
                        </li>
                })}
            </ul>
        </div>
    )
}

export default ListEntities
